<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>校区管理</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-box" style="min-height:720px">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <AppLink v-for="(item, key) of groupData" :key="key" :title="key" parentKey='orginization' :list="item"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',

  data() {
    return {
      loading: false,
      groupData: {}
    }
  },

  created() {
    this.getList()
  },

  methods: {
    async getList() {
      this.loading = true
      let list = await this.$store.dispatch('orginizationListAction')
      this.groupData = list
      this.loading = false
    }
  }
}
</script>
